
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.staticResourceWrapper{text-align:left;padding:20px;max-width:700px;margin-bottom:100px;border-radius:10px
}
.rm-accordion-wrap{margin-left:5px;margin-right:5px
}
.rm-accordion-title{min-height:59px;border-radius:5px;border:solid 0.5px #d8d8d8;margin-top:11px;background-color:white;margin-bottom:15px;text-transform:capitalize;display:-webkit-box;display:-ms-flexbox;display:flex
}
.rm-accordion-title .rm-accordion-title-wrap{-webkit-box-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center
}
.rm-accordion-title .rm-accordion-title-wrap h2{line-height:1.25;font-size:20px;font-weight:bold;margin:0px
}
.rm-accordion-title .expand-button-wrap{width:0px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center
}
.rm-accordion-title .expand-button-wrap .expand-icon{margin-top:0px;margin-right:0px;width:20px;margin-left:-40px
}
.rm-accordion-content{margin-top:5px;min-height:166px;border-radius:5px;border:solid 0.5px #d8d8d8;padding-left:20px;padding-top:20px;padding-right:29px;padding-bottom:11px;background-color:white;margin-bottom:15px
}
.rmvue-grid .data-row{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:space-evenly;-ms-flex-pack:space-evenly;justify-content:space-evenly
}

.staticResourceWrapper{text-align:left;padding:20px;max-width:700px;margin-bottom:100px;border-radius:10px
}
.rm-accordion-wrap{margin-left:5px;margin-right:5px
}
.rm-accordion-title{min-height:59px;border-radius:5px;border:solid 0.5px #d8d8d8;margin-top:11px;background-color:white;margin-bottom:15px;text-transform:capitalize;display:-webkit-box;display:-ms-flexbox;display:flex
}
.rm-accordion-title .rm-accordion-title-wrap{-webkit-box-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center
}
.rm-accordion-title .rm-accordion-title-wrap h2{line-height:1.25;font-size:20px;font-weight:bold;margin:0px
}
.rm-accordion-title .expand-button-wrap{width:0px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center
}
.rm-accordion-title .expand-button-wrap .expand-icon{margin-top:0px;margin-right:0px;width:20px;margin-left:-40px
}
.rm-accordion-content{margin-top:5px;min-height:166px;border-radius:5px;border:solid 0.5px #d8d8d8;padding-left:20px;padding-top:20px;padding-right:29px;padding-bottom:11px;background-color:white;margin-bottom:15px
}
.rmvue-grid .data-row{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:space-evenly;-ms-flex-pack:space-evenly;justify-content:space-evenly
}
